
import { mapGetters } from 'vuex'

import { mixin as clickaway } from 'vue-clickaway'

export default {
  mixins: [
    clickaway
  ],

  props: {
    mode: {
      required: true,
      type: String
    }
  },

  asyncData ({ from, store }) {
    if (from) {
      store.dispatch('routing/setFrom', (from.fullPath || null))
    }
  },

  data: () => ({
    error: '',
    type: 'cart',
    paymentCompany: {
      isActive: false
    },
    installment: {
      initial: 0
    },
    term: {
      selected: {},
      isActive: false
    },
    setTypeRoutes: [
      '/cart',
      '/installment'
    ]
  }),

  computed: {
    ...mapGetters({
      settings: 'settings',
      cart: 'cart/cart',
      currency: 'currency',
      from: 'routing/from'
    }),

    // totalCartDeliveryPrice () {
    //   return 0
    // },

    totalCartDiscount () {
      return this.cart.items.reduce((total, item) => {
        const price = item.store.price.current + item.warranties.reduce((total, item) => total + item.price, 0) + item.addons.reduce((total, item) => total + item.price, 0)

        let discount = 0

        if (item.coupon && item.coupon.discount.type.type === 'percent') {
          discount = Math.round(price * item.coupon.discount.percent / 100)
        } else if (item.coupon && item.coupon.discount.type.type === 'amount') {
          discount = item.coupon.discount.amount.amount
        }

        return total + item.quantity * discount
      }, 0)
    },

    totalCartPrice () {
      const price = this.cart.items.reduce((total, item) => {
        let price = item.store.price.current + item.warranties.reduce((total, item) => total + item.price, 0) + item.addons.reduce((total, item) => total + item.price, 0)

        if (item.coupon && item.coupon.discount.type.type === 'percent') {
          price = price - Math.round(price * item.coupon.discount.percent / 100)
        } else if (item.coupon && item.coupon.discount.type.type === 'amount') {
          price = price - item.coupon.discount.amount.amount
        }

        return total + item.quantity * price
      }, 0)

      let commission = 0

      if (this.type === 'installment' && Object.keys(this.cart.creditProduct).length > 0 && !this.cart.creditProduct.included) {
        if (this.cart.creditProduct.calculation === 'simple') {
          commission = Math.ceil(price / 100 * this.cart.term.percent)
        } else if (this.cart.creditProduct.calculation === 'complex') {
          commission = Math.ceil((price / (1 - this.cart.term.percent / 100)) - price)
        } else if (this.cart.creditProduct.calculation === 'monthly') {
          commission = Math.ceil(price / 100 * this.cart.term.percent * this.cart.term.payments)
        }
      }

      return price + commission
    },

    installmentPerMonthPrice () {
      return Math.ceil((this.totalCartPrice - this.cart.installment.initial) / this.cart.term.payments)
    },

    installmentInitial: {
      get () {
        return this.cart.installment.initial
      },
      set (value) {
        let installmentInitial = this.positiveInt(value)
        installmentInitial = installmentInitial > this.totalCartPrice ? this.totalCartPrice : installmentInitial

        this.$store.dispatch('checkout/setInstallmentInitial', installmentInitial)
        this.$store.dispatch('cart/setInitialInstallment', installmentInitial)
      }
    }
  },

  watch: {
    'cart.creditProductsLoading': {
      handler (val) {
        this.$nextTick(() => {
          if (this.$refs.footer) {
            this.$emit('heightChange', this.$refs.footer.clientHeight)
          }
        })
      },
      immediate: false,
      deep: true
    },
    'cart.loading': {
      handler (val) {
        if ((this.cart.creditProducts === null || this.cart.creditProducts.length === 0) && this.cart.id && this.cart.creditProductsLoading === false) {
          this.$store.dispatch('cart/setCartCreditProductsLoading', true)

          this.getCartCreditProducts()
        }
      },
      immediate: false,
      deep: true
    }
  },

  mounted () {
    if (this.setTypeRoutes.includes(this.$route.path)) {
      this.type = this.$route.path.replace(/^\/|\/$/g, '')
    }

    if (this.cart.id && this.cart.creditProductsLoading === false && (this.cart.creditProducts === null || this.cart.creditProducts.length === 0)) {
      this.getCartCreditProducts()
    } else if (this.cart.creditProductsLoading === true && this.cart.creditProducts !== null) {
      this.$store.dispatch('cart/setCartCreditProductsLoading', false)
    }

    this.$nextTick(() => {
      if (this.$refs.footer) {
        this.$emit('heightChange', this.$refs.footer.clientHeight)
      }
    })
  },

  methods: {
    async getCartCreditProducts () {
      if (this.cart.creditProducts === null || this.cart.creditProducts.length === 0) {
        await this.$store.dispatch('cart/setCartCreditProducts')
      }
    },

    hideCreditProductsDropDown () {
      this.paymentCompany.isActive = false
    },

    async changeCreditProduct (paymentCompany) {
      await this.$store.dispatch('cart/setCartCreditProduct', paymentCompany)

      this.hideTermsDropDown()

      this.hideCreditProductsDropDown()

      // this.$store.dispatch('cart/syncCart', false)
    },

    hideTermsDropDown () {
      this.term.isActive = false
    },

    setCartType () {
      const type = this.cart.type === 'installment' ? 'cart' : 'installment'

      this.$store.dispatch('cart/setCartType', type)

      if (this.cart.type === 'cart') {
        this.$store.dispatch('cart/setCartCreditProduct', {})
        this.$store.dispatch('cart/setCartTerm', {
          payments: 1
        })
      }

      this.$router.push(this.localePath({ name: type }))
    },

    changeTerm (term) {
      this.$store.dispatch('cart/setCartTerm', term)

      this.hideTermsDropDown()
    },

    positiveInt (val) {
      val = parseInt(val)

      if (val < 0) {
        val = val * -1
      }

      return val || 0
    }
  }
}
