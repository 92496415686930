
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      isActive: false
    }
  },

  computed: {
    ...mapGetters({
      callcenter: 'callcenter',
      callback: 'modal/callback'
    }),

    operating () {
      const schedule = this.callcenter.schedule.days
      const timeFormat = 'hh:mm'
      let operating

      if (Array.isArray(schedule)) {
        const moment = this.$moment().locale(this.$i18n.locale)
        const weekday = moment.isoWeekday()
        const open = this.$moment(schedule[weekday - 1].open, timeFormat)
        const close = this.$moment(schedule[weekday - 1].close, timeFormat)

        if (moment.isBefore(open) && schedule[weekday - 1].open && schedule[weekday - 1].close) {
          operating = {
            title: this.$t('callcenter.today.notYet.title'),
            subtitle: this.$t('callcenter.today.notYet.subtitle', {
              open: schedule[weekday - 1].open,
              close: schedule[weekday - 1].close
            }),
            class: 'text-warning'
          }
        } else if (moment.isBetween(open, close) && schedule[weekday - 1].open && schedule[weekday - 1].close) {
          operating = {
            title: this.$t('callcenter.today.operating.title'),
            subtitle: this.$t('callcenter.today.operating.subtitle', {
              close: schedule[weekday - 1].close
            }),
            class: 'text-success'
          }
        } else {
          for (let i = 1; i <= 7; i++) {
            let nextDay = weekday + i

            if (nextDay > 7) {
              nextDay -= 7
            }

            if (nextDay in schedule && schedule[nextDay - 1].open && schedule[nextDay - 1].close) {
              let title = ''

              if (i === 1 && (!schedule[weekday - 1].open || !schedule[weekday - 1].close)) {
                title += `${this.$t('callcenter.today.dayOff.title')}<br>`
              } else if (i > 1) {
                title += `${this.$t('callcenter.daysOff.title')}<br>`
              }

              if (i === 1) {
                title += this.$t('callcenter.tomorrow.title')
              } else {
                title += this.$t('callcenter.nextDay.title', {
                  weekday: this.$moment().isoWeekday(nextDay).format('dd').toUpperCase()
                })
              }

              operating = {
                title,
                subtitle: this.$t('callcenter.nextDay.subtitle', {
                  open: schedule[nextDay - 1].open,
                  close: schedule[nextDay - 1].close
                }),
                class: 'text-warning'
              }

              break
            }
          }

          if (operating === undefined) {
            operating = {
              title: this.$t('callcenter.today.dayOff.title'),
              subtitle: '',
              class: 'has-text-grey'
            }
          }
        }
      } else if (schedule === '') {
        operating = {
          title: this.$t('callcenter.allDay.title'),
          subtitle: this.$t('callcenter.allDay.subtitle'),
          class: 'has-text-grey'
        }
      }

      return operating
    }
  },

  methods: {
    toggleCallbackModal () {
      this.isActive = false

      if (this.callcenter.contacts.others.callback && this.callcenter.contacts.others.callback.length) {
        this.$store.dispatch('modal/setCallbackActive', !this.callback.active)
      }
    },

    scheduleHasMessage (schedule) {
      return schedule.message && schedule.message.replace(/(<([^>]+)>)/gi, '') !== ''
    }
  }
}
