
import { mapGetters } from 'vuex'
import { mixin as clickaway } from 'vue-clickaway'

import RemoveModal from '@/components/modals/RemoveCartModal'

export default {
  components: {
    RemoveModal
  },

  mixins: [
    clickaway
  ],

  props: {
    index: {
      required: true,
      type: Number
    },
    item: {
      required: true,
      type: Object
    },
    mode: {
      required: true,
      type: String
    }
  },

  data: () => ({
    removeModal: false,
    coupon: {
      code: '',
      loading: false,
      removing: false,
      active: false,
      notification: {
        timer: 3000,
        active: false,
        message: '',
        class: ''
      }
    }
  }),

  computed: {
    ...mapGetters({
      cart: 'cart/cart',
      currency: 'currency',
      country: 'country'
    }),

    initial () {
      return this.item.price // * this.item.quantity
    },

    price () {
      return this.item.store.price.current + this.item.warranties.reduce((total, item) => total + item.price, 0) + this.item.addons.reduce((total, item) => total + item.price, 0)
    },

    total () {
      return this.price * this.item.quantity
    },

    discount () {
      if (this.item.coupon === null) {
        return 0
      }

      if (this.item.coupon.discount.type.type === 'percent') {
        return Math.round(this.total * this.item.coupon.discount.percent / 100)
      } else {
        return this.item.coupon.discount.amount.amount
      }
    },

    totalDiscount () {
      return this.item.quantity * this.discount
    },

    totalWithDiscount () {
      return this.total - this.discount
    },

    cheaperTogetherWith () {
      if (!this.item.cheaperTogetherWith) {
        return null
      }

      return this.cart.items.find(i => i.id === this.item.cheaperTogetherWith.id)
    }
  },

  mounted () {
    this.$nextTick(() => {
      if (this.$refs.item) {
        this.$emit('heightChange', this.$refs.item.clientHeight)
      }
    })
  },

  methods: {
    openConditionStatusInfoModal (conditionStatus) {
      this.$store.dispatch('modal/setInfoModalTitle', conditionStatus.title)
      this.$store.dispatch('modal/setInfoModalDescription', conditionStatus.description)
      this.$store.dispatch('modal/setInfoModalActive', true)
    },

    applyCoupon () {
      this.coupon.loading = true

      this.$axios.$post(`${this.$i18n.locale}/${this.country.iso_code}/cart/${this.cart.id}/items/${this.item.id}/coupons`, { code: this.coupon.code })
        .then((res) => {
          this.$store.dispatch('cart/addCouponToCartItem', res.data)

          this.coupon.notification.message = res.message
          this.coupon.notification.class = 'is-success'
          this.coupon.notification.active = true

          setTimeout(() => {
            this.coupon.notification.active = false
          }, this.coupon.notification.timer)
        })
        .catch((error) => {
          this.coupon.notification.message = error.response.data.error
          this.coupon.notification.class = 'is-danger'
          this.coupon.notification.active = true
        })
        .finally(() => {
          this.coupon.code = ''
          this.coupon.active = false
          this.coupon.loading = false
        })
    },

    removeCoupon () {
      this.coupon.removing = true

      this.$axios.$delete(`${this.$i18n.locale}/${this.country.iso_code}/cart/${this.cart.id}/items/${this.item.id}/coupons/${this.item.coupon.id}`)
        .then((res) => {
          this.$store.dispatch('cart/removeCouponFromCartItem', this.item)

          this.coupon.notification.message = res.message
          this.coupon.notification.class = 'is-success'
          this.coupon.notification.active = true

          setTimeout(() => {
            this.coupon.notification.active = false
          }, this.coupon.notification.timer)
        })
        .catch((error) => {
          this.coupon.notification.message = error
          this.coupon.notification.class = 'is-danger'
          this.coupon.notification.active = true
        })
        .finally(() => {
          this.coupon.code = ''
          this.coupon.active = false
          this.coupon.removing = false
        })
    },

    openCouponInput () {
      this.toggleCouponInput()

      this.$nextTick(() => this.$refs.coupon.focus())
    },

    toggleCouponInput () {
      this.coupon.active = !this.coupon.active
    }
  }
}
