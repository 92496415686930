
export default {
  props: {
    crumbs: {
      required: true,
      default () {
        return []
      },
      type: Array
    }
  },

  data () {
    return {
      showActiveCrumbs: true
    }
  }
}
